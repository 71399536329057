<template>
  <div class="container">
    <p class="title">{{ detailInfo.Name }}</p>
    <img class="cover" :src="detailInfo.KeyImg" >
    <div class="activityContent" v-html="detailInfo.Remarks"></div>
    <div class="SignUpNumber">已报名：{{ detailInfo.SignUpNumber }}，剩余名额：{{ detailInfo.RemainingPersonnel }}</div>
    <div class="enrollBtn" v-if="detailInfo.SignUpState == null && detailInfo.RemainingPersonnel != 0 && isEnroll && timeStatus(detailInfo.StartTime)" @click="signUp">立即报名</div>
    <div class="enrollBtn1" v-else-if="detailInfo.SignUpState == null && !isEnd && detailInfo.RemainingPersonnel != 0 && (!isEnroll || !timeStatus(detailInfo.StartTime))">立即报名</div>
    <div class="enrollBtn1" v-else-if="detailInfo.SignUpState == null && !isEnd && detailInfo.RemainingPersonnel == 0">名额已满</div>
    <div class="enrollBtn1" v-else-if="detailInfo.SignUpState == null && isEnd">活动已结束</div>
    <div class="enrollBtn1" v-else>{{ detailInfo.SignUpState === 0 ? '已报名，待审核' : detailInfo.SignUpState === 1 ? '报名成功' : '报名失败' }}</div>

    <van-overlay :show="enrollShow">
      <div class="enrollWrapper" @click.stop>
        <div class="enrollBox">
          <van-icon class="closeIcon" name="close" @click="enrollShow = false" />
          <p class="enrollBox_title">报名信息</p>
          <div class="inputBox">
            <span class="lable">姓名</span>
            <input class="input" type="text" v-model="Name" />
          </div>
          <div class="inputBox">
            <span class="lable">医院</span>
            <!-- <input class="input" type="text" v-model="HospitalName" /> -->
            <van-popover v-model="showHospitalPopover" trigger="click">
              <div class="HospitalList">
                <span v-for="item in HospitalColumns" :key="item.HospitalID" @click="selectHospital(item.HospitalName)">{{ item.HospitalName }}</span>
              </div>
              <template #reference>
                <input class="popoverInput" type="text" v-model="HospitalName" />
              </template>
            </van-popover>
          </div>
          <div class="inputBox">
            <span class="lable">科室</span>
            <!-- <input class="input" type="text" v-model="Department" /> -->
            <van-popover v-model="showDepartmentPopover" trigger="click">
              <div class="HospitalList">
                <span v-for="item in DepartmentColumns" :key="item.Value" @click="selectDepartment(item.Name)">{{ item.Name }}</span>
              </div>
              <template #reference>
                <input class="popoverInput" type="text" v-model="Department" />
              </template>
            </van-popover>
          </div>
          <div class="inputBox">
            <span class="lable">联系方式</span>
            <input class="input" type="number" v-model="ContactInformation" />
          </div>
          <!-- <div class="inputBox">
            <span class="lable">验证码</span>
            <div class="CodeBox">
              <input class="input" type="number" v-model="Code" maxlength="6" />
              <span
                class="sendCode"
                v-if="!alreadySend"
                @click="sendCode"
                style="background: #d11919;"
                >{{isSendCode ? '重新获取' : '获取验证码'}}</span>
              <span class="sendCode" v-else>{{ second + "S" }}</span>
            </div>
          </div> -->
          <div class="submitBtn" @click="submitEnroll">提交</div>
        </div>
      </div>
    </van-overlay>

    <div class="rowbtn">
      <span class="headline">评论</span>
      <div class="plbtn" v-if="detailInfo.IsConmment" @click="showCommentPopup = true">
        <img src="@/assets/images/appraise.png" />
        <span>我要点评</span>
      </div>
    </div>

    <van-popup
      v-model="showCommentPopup"
      closeable
      position="bottom"
      :style="{ height: '28%' }"
      class="plPopup"
    >
      <van-cell-group class="plinput">
        <van-field v-model="comment" placeholder="请输入评论内容" />
      </van-cell-group>
      <van-button type="primary" class="rateupload" @click="submitComment"
        >提交</van-button
      >
    </van-popup>

    <div class="plmain">
      <div
        class="plrow noComment"
        v-if="commentList.length == 0"
      >
        <img src="@/assets/images/none_comm@2x.png" />
        <span>暂无评论</span>
      </div>
      <div
        class="plrow"
        v-else
        v-for="(item, index) in commentList"
        :key="index"
      >
        <div class="pltop">
          <div class="tx">
            <img class="plicon" style="" :src="item.Headimgurl" />
          </div>
          <div class="plname">{{ item.CreateUser }}</div>
          <div class="pltime">{{ item.CreateTime | dateFilter(item.CreateTime) }}</div>
        </div>
        <div class="pltext">
          {{ item.Content }}
        </div>

        <!-- <div class="pldz">
          <img
            class="plicon"
            style=""
            v-if="item.LikeState == false"
            src="@/assets/images/like.png"
            @click="pldz(item.Id)"
          />
          <img
            class="plicon"
            style=""
            v-else
            src="@/assets/images/like_active.png"
            @click="plqxdz(item.Id)"
          />
          <span>{{ item.LikeCount }}</span>
        </div> -->
      </div>
    </div>

    <div class="bottombar">
      <div class="bottomrow" @click="goback">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/back.png"
          />
        </div>
        <div class="bottomtext">返回</div>
      </div>
      <div class="bottomrow" @click="likes">
        <div>
          <img
            class="bottomimg"
            :src="detailInfo.IsLike ? require('@/assets/images/good_ny_icon_alt@2x.png') : require('@/assets/images/good_ny_icon_nor@2x.png')"
          />
        </div>
        <div class="bottomtext">点赞</div>
      </div>
      <div class="bottomrow" @click="noLikes">
        <div>
          <img
            class="bottomimg rotateImg"
            :src="detailInfo.IsStepOn ? require('@/assets/images/good_ny_icon_alt@2x.png') : require('@/assets/images/good_ny_icon_nor@2x.png')"
          />
        </div>
        <div class="bottomtext">踩一踩</div>
      </div>
      <div class="bottomrow" @click="sharefunc">
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/share_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">转发</div>
      </div>
    </div>

    <div v-if="shareShow" class="shlog">
      <div class="shin">
        <img class="shlog1" src="@/assets/images/share_action_icon@2x.png" />
        <img class="shlog2" src="@/assets/images/pic_action_icon@2x.png" />
        <div class="shlog4">点击右上角“...”，分享到</div>
        <div class="shlog3">
          <img class="sslog" src="@/assets/images/wx_action_icon@2x.png" />
          <span>微信好友或</span>
          <img class="sslog1" src="@/assets/images/pyq_action_icon@2x.png" />
          <span>微信朋友圈~</span>
          <div class="known" @click="shareShow = false">知道了</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";

const delay = (function() {
 let timer = 0;
 return function(callback, ms) {
  clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: 'ActivityDetail',
  data() {
    return {
      detailInfo: {},
      isEnd: false,
      enrollShow: false,
      isEnroll: false,
      Name: '',
      showHospitalPopover: false,
      HospitalColumns: [],
      SelectHospital: '',
      HospitalName: '',
      showDepartmentPopover: false,
      DepartmentColumns: [],
      Department: '',
      ContactInformation: '',
      showCommentPopup: false,
      comment: '',
      commentList: [],
      shareShow: false,
      kpng: require("../../assets/images/k.png"),
      shareurl: {},
      weixinvalue: {
        appId: "",
        timestamp: "",
        noncStr: "",
        signature: "",
      },
      utype: "",
      backlog: {
        ShareUid: 0,
        SeeUid: 0,
        ShareUri: "",
        Code: "",
        ShareId: 0,
        seeType: "",
      },
      sharefrom: 0,
      sharecode: "",
      ssurl: "",
      Code: "", // 验证码
      second: 60,
      alreadySend: false, // 是否发送验证码
      isSendCode: false,
    }
  },
  computed: {
    ...mapState(["userInfo", "Openid"]),
    id() {
      return this.$route.query.id;
    }
  },
  watch: {
    HospitalName(newValue) {
      if (newValue == this.SelectHospital) {
        this.showHospitalPopover = false;
      } else {
        delay(() => {
          this.GetHospital();
        }, 300);
      }
    }
  },
  created() {
    this.getDetail();
    this.getDepartment();
    this.initShare();
    this.fromLog();
    this.getActSU(this.userInfo.Phone)
  },
  methods: {
    getDetail() {
      this.$axios.get('/Api/Api/Web/Activity/GetModel?id=' + this.id).then(res => {
        this.detailInfo = res.Data;
        this.commentList = res.Data.acList;
        this.isEnd = this.hasEnded(res.Data.EndTime);
      })
    },
    hasEnded(endTime) {
      const endDate = new Date(endTime);
      const now = new Date();
      return now > endDate;
    },
    async GetHospital() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.HospitalName);
      let data = res.Data;
      if (data.length > 0) {
        this.showHospitalPopover = true;
        this.HospitalColumns = data;
      } else {
        this.showHospitalPopover = false;
        this.HospitalColumns = [];
      }
    },
    signUp() {
      this.enrollShow = true;
      this.Name = this.userInfo.RealName;
      this.HospitalName = this.userInfo.HospitalTitle;
      this.Department = this.userInfo.SubjectName;
      this.ContactInformation = this.userInfo.Phone;
    },
    getActSU(name) {
      this.$axios.get('/Api/Api/Web/Activity/GetActSU?name=' + name).then(res => {
        if(res.Data) {
          this.isEnroll = true;
        }
      })
    },
    selectHospital(name) {
      if(this.HospitalName == name) {
        this.showHospitalPopover = false;
      } else {
        this.HospitalName = name;
        this.SelectHospital = name;
      }
    },
    // 获取科室
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=1&pcode=').then(res => {
        this.DepartmentColumns = res.Data;
      })
    },
    selectDepartment(name) {
      this.Department = name;
      this.showDepartmentPopover = false;
    },
    
    // 发送验证码
    sendCode() {
      let errMsg = "";
      if (this.ContactInformation) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.ContactInformation)) {
          errMsg = "手机号码格式错误！";
        }
      } else {
        errMsg = "请输入手机号码！";
      }

      if (errMsg == "") {
        this.timer();
        this.$axios.get('/Api/Api/Web/GetWxUser/GetVCode', {
          params: {
            Phone: this.ContactInformation,
            Type: 3
          }
        }).then((res) => {
          if (res.RetCode == '10000') {
            this.validateNum = 0;
          }
        })
      } else {
        this.$toast(errMsg);
      }
    },
    // 验证码计时
    timer() {
      this.isSendCode = true;
      this.alreadySend = true;
      let promise = new Promise((resolve) => {
        let setTimer = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.alreadySend = false;
            resolve(setTimer);
          }
        }, 1000);
      });
      promise.then((setTimer) => {
        clearInterval(setTimer);
      });
    },
    submitEnroll() {
      if (!this.Name) {
        this.$toast('请输入姓名！');
        return false;
      }

      if (!this.HospitalName) {
        this.$toast('请输入医院！');
        return false;
      }

      if (!this.Department) {
        this.$toast('请输入科室！');
        return false;
      }

      if (this.ContactInformation) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.ContactInformation)) {
          this.$toast('手机号码格式错误！');
          return false;
        }
      } else {
        this.$toast('请输入手机号码！');
        return false;
      }

      // if (!this.Code) {
      //   this.$toast('请输入验证码！');
      //   return false;
      // }

      this.$dialog.confirm({
        title: '提示',
        message: '确认个人填写信息无误！',
      }).then(() => {
        this.$axios.post('/Api/Api/Web/Activity/SignUp', {
          ActivityId: this.id,
          Name: this.Name,
          HospitalName: this.HospitalName,
          Department: this.Department,
          ContactInformation: this.ContactInformation
        }).then(res => {
          if(res.RetCode == '10000') { 
            this.enrollShow = false;
            this.$toast.success('提交成功');
            this.getDetail();
          } else {
            this.$toast(res.RetMsg);
          }
        })
      })
      .catch(() => {});
    },
    submitComment() {
      if(this.comment) {
        this.$axios.post('/Api/Api/Web/Activity/AddComm', {
          ActivityId: this.id,
          Content: this.comment
        }).then(res => {
          this.$dialog.alert({
              message: "提交成功，审核通过后可以看到该评论",
          }).then(() => {
            this.comment = '';
            this.showCommentPopup = false;
          });
        })
      } else {
        this.$toast.fail('内容不能为空');
      }
    },
    goback() {
      this.$router.go(-1);
    },
    initShare() {
      let that = this;
      const entryLink = utils.getEntryLink();
      that.$axios
        .get("/Api/Api/web/WechatHelper/GetWechat?backUrl=" + entryLink)
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.Data.AppId,
            timestamp: res.Data.TimesTamp,
            nonceStr: res.Data.NonceStr,
            signature: res.Data.She1Str,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });
          wx.checkJsApi({
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              "updateAppMessageShareData", // 分享到朋友圈接口
              "updateTimelineShareData", //  分享到朋友接口
            ],
            success: async function (res) {
              var url = await that.getShareUrl();
              wx.ready(() => {
                wx.updateAppMessageShareData({
                  title: that.detailInfo.Name,
                  link: url,
                  desc: '',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
                wx.updateTimelineShareData({
                  title: that.detailInfo.Name,
                  link: url,
                  desc: '',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
              });
            },
          });
        });
    },
    sharefunc() {
      this.shareShow = true;
      //记录分享日志
      this.sharelog();
    },
    sharelog() {
      //alert("分享记录-开始");
      let that = this;
      that.backlog = {
        ShareUid: this.sharefrom,
        SeeUid: this.userInfo.Id,
        ShareUri: window.location.href,
        Code: this.sharecode,
        ShareId: this.id,
        seeType: this.utype,
        Type: 2
      };
      //alert("分享记录-拿到信息"+JSON.stringify(that.backlog));

      that.$axios
        .post("/Api/Api/Web/ShareLog/AddShare", that.backlog)
        .then((res) => {});
    },
    getShareUrl() {
      let that = this;
      this.shareurl.customerid = this.userInfo.Id;      
      var shurl = window.location.href;
      var shindex = shurl.indexOf("&seetype");
      if (shindex > -1) {
        shurl = shurl.substr(0, shindex);
      }
      //console.log("85825225",window.location.href,shurl)
      this.shareurl.url = encodeURIComponent(shurl);
      return new Promise((resolve) => {
        that.$axios
          .get(
            "/Api/Api/web/ShareLog/GetShareUrl?url=" +
              that.shareurl.url +
              "&customerid=" +
              this.shareurl.customerid
          )
          .then((res) => {
            resolve(res.Data.Url);
          });
      });
    },
    //记录分享人行为数据
    fromLog() {
      //alert("记录分享人行为数据-开始");
      //从url参数中获取分享人id和code
      this.utype = this.$route.query.seetype;
      //alert("记录分享人行为数据-utype："+this.utype);
      if (this.utype == 2) {
        //alert("记录分享人行为数据-进入-utype=2");
        //sharefrom  sharecode
        this.sharefrom = this.$route.query.shareuid;
        this.sharecode = this.$route.query.code;
        this.sharelog();
      }
    },
    likes() {
      let url = this.detailInfo.IsLike ? '/Api/Api/Web/Activity/DelAct' : '/Api/Api/Web/Activity/UpdateAct';
      this.$axios.post(url, {
        ActId: parseInt(this.id),
        Type: 3,
        CusId: this.userInfo.Id
      }).then(res => {
        this.getDetail();
      })
    },
    noLikes() {
      let url = this.detailInfo.IsStepOn ? '/Api/Api/Web/Activity/DelAct' : '/Api/Api/Web/Activity/UpdateAct';
      this.$axios.post(url, {
        ActId: parseInt(this.id),
        Type: 8,
        CusId: this.userInfo.Id
      }).then(res => {
        this.getDetail();
      })
    },
    timeStatus(startTime) {
      const currentTime = new Date();
      const time1 = new Date(startTime);
      if (currentTime >= time1) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px 15px 110px;
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .cover {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .activityContent {
    margin-bottom: 10px;
  }
  .SignUpNumber {
    font-size: 14px;
    text-align: right;
  }
  .van-overlay {
    z-index: 999;
  }
  .enrollBtn {
    position: fixed;
    left: 5%;
    bottom: 70px;
    z-index: 99;
    width: 90%;
    height: 45px;
    background-color: #CA001B;
    border-radius: 45px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
  }
  .enrollBtn1 {
    position: fixed;
    left: 5%;
    bottom: 70px;
    z-index: 99;
    width: 90%;
    height: 45px;
    background-color: #cccccc;
    border-radius: 45px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
  }
  .enrollWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .enrollBox {
    position: relative;
    padding: 10px;
    width: 80%;
    border-radius: 8px;
    background-color: #fff;
    .closeIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 20px;
    }
    .enrollBox_title {
      font-size: 15px;
      text-align: center;
      line-height: 35px;
      margin-bottom: 5px;
    }
    .inputBox {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .lable {
        display: inline-block;
        width: 60px;
        font-size: 14px;
        text-align: right;
        margin-right: 10px;
      }
      .input {
        width: 0;
        flex: 1;
        border: 1px solid #cccccc;
        height: 35px;
        border-radius: 4px;
        padding: 0 6px;
        box-sizing: border-box;
        font-size: 14px;
      }
      .van-popover__wrapper {
        flex: 1;
        .popoverInput {
          width: 100%;
          border: 1px solid #cccccc;
          height: 35px;
          border-radius: 4px;
          padding: 0 6px;
          box-sizing: border-box;
          font-size: 14px;
        }
      }
      .CodeBox {
        display: flex;
        align-items: center;
        flex: 1;
        .input {
          flex: 1;
          margin-right: 6px;
        }
        .sendCode {
          min-width: 60px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          padding: 0 4px;
          box-sizing: border-box;
          border-radius: 4px;
          font-size: 12px;
          background-color: #CCCCCC;
          color: #FFFFFF;
        }
      }
    }
    .submitBtn {
      width: 80px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #FFFFFF;
      background-color: #CA001B;
      border-radius: 5px;
      font-size: 14px;
      margin: 18px auto 8px;
    }
  }

  .rowbtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2em auto 1em;
    .headline {
      font-size: 16px;
      font-weight: bold;
      // margin-bottom: 0.5em;
    }
  }
  .plbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    color: #CA001B;
    border: 1px solid #CA001B;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    img {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  }
  .plmain {
    width: 90%;
    margin: auto;
    margin-bottom: 2em;
  }
  .plrow {
    position: relative;
    margin-bottom: 1em;
  }
  .plPopup {
    padding-top: 3em;
  }
  .plinput {
    margin: auto;
    margin-bottom: 2em;
    width: 90%;
    border: 1px solid #CA001B;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1em;
  }
  .rateupload {
    border: 0;
    width: 67%;
    text-align: center;
    display: block;
    margin: 1em auto !important;
    margin-top: 1.5em !important;
    border-radius: 0.5em;
    background: #d11919;
    font-size: 14px;
  }
  .tx {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    img {
      width: 100%;
      display: block;
    }
  }
  .plname {
    display: inline-block;
    font-size: 14px;
    margin-left: 0.5em;
    color: #999999;
  }
  .pltop {
    position: relative;
  }
  .pltime {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 22%;
    color: #999999;
  }
  .pltext {
    font-size: 14px;
    color: #333333;
    padding-left: 34px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EEEEEE;
  }
  .pldz {
    margin-left: 34px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 12px;
    img {
      width: 14px;
      height: 14px;
    }
    span {
      margin-left: 4px;
      color: #333333;
      font-size: 12px;
    }
  }
  .noComment {
    text-align: center;
    img {
      width: 180px;
      margin-bottom: 20px;
    }
    span {
      display: block;
      font-size: 12px;
      color: #CCCCCC;
    }
  }

  .bottombar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    width: 100%;
    text-align: center;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
  .bottomrow {
    display: inline-block;
    width: 25%;
  }
  .bottomimg {
    width: 1.5em;
  }
  .rotateImg {
    transform: rotate(180deg);
  }
  .bottomtext {
    font-weight: bold;
    font-size: 0.9em;
  }
  .shlog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    .shin {
      position: fixed;
      width: 100%;
      top: 20px;
      left: 0;
      .shlog1 {
        display: block;
        width: 16%;
        margin: 0 auto;
        margin-top: 0.3rem;
        margin-right: 9%;
      }
      .shlog2 {
        width: 61%;
        display: block;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .shlog3 {
        color: #fff;
        font-size: 16px;
        width: 70%;
        margin: 0 auto;
      }
      .sslog {
        width: 22px;
        margin-right: 5px;
        vertical-align: middle;
      }
      .sslog1 {
        vertical-align: middle;
        width: 22px;
        margin: 0 5px;
      }
      span {
        vertical-align: middle;
      }
      .shlog4 {
        color: #fff;
        font-size: 16px;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        width: 70%;
      }
      .known {
        width: 56%;
        text-align: center;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.1);
        border: 2px dashed #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 1rem;
      }
    }
  }
}

.HospitalList {
  width: 220px;
  font-size: 12px;
  span {
    display: block;
    padding: 6px 10px;
  }
  span:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}

/deep/ .activityContent img {
  width: 100%;
}
/deep/ .activityContent > p > img {
  width: 100%;
}
</style>